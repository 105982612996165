// components/PostCard.js

import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Button } from '@mui/material';

function PostCard({ content, mediaFiles, onPublish, onPublishToFacebook }) {
  return (
    <Card sx={{ boxShadow: 2, borderRadius: 4, backgroundColor: '#f9f9f9', width: '100%', maxWidth: '100%' }}>
      <CardContent sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        <Avatar src="/path/to/profile-pic.jpg" alt="User" sx={{ width: 50, height: 50 }} />
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Utilisateur</Typography>
          <Typography variant="body1" sx={{ color: '#000' }}>{content}</Typography>
          
          {/* Conteneur des fichiers médias avec taille limitée */}
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
            {mediaFiles.map((file, index) => (
              <Box key={index} sx={{ position: 'relative', width: 80, height: 80, flexShrink: 0 }}>
                {file.type.startsWith("image") ? (
                  <img src={URL.createObjectURL(file)} alt="media" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 4 }} />
                ) : (
                  <video src={URL.createObjectURL(file)} controls style={{ width: '100%', height: '100%', borderRadius: 4 }} />
                )}
              </Box>
            ))}
          </Box>

          {/* Boutons de publication */}
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onPublish}
            >
              Publier
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={onPublishToFacebook}
            >
              Publier sur Facebook
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PostCard;
