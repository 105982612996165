// src/pages/Dashboard/SocialMediaAuthorization/SocialNetMediaConfigurationPage.js

import React from 'react';
import { Container, Typography } from '@mui/material';
import FacebookAuth from './FacebookAuth';

function SocialNetMediaConfigurationPage() {
  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Configuration des Réseaux Sociaux
      </Typography>
      <Typography variant="body1" gutterBottom align="center" sx={{ mb: 3 }}>
        Connectez vos comptes de réseaux sociaux pour publier du contenu directement depuis notre application.
      </Typography>

      {/* Carte d'autorisation pour Facebook */}
      <FacebookAuth />
    </Container>
  );
}

export default SocialNetMediaConfigurationPage;
