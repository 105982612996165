// src/config/socialNetworkConfig.js

const socialNetworkConfig = {
    facebook: {
      appId: '1621034188488054',                // Remplacez par votre App ID Facebook
      redirectUri: 'https://viralist.fr/callback', // URL de redirection après authentification
      scopes: 'pages_manage_posts,pages_read_engagement,pages_manage_engagement,pages_show_list', // Permissions requises
      apiVersion: 'v12.0',                      // Version de l'API Graph utilisée
    },
    twitter: {
      apiKey: 'YOUR_TWITTER_API_KEY',           // API Key de Twitter
      apiSecret: 'YOUR_TWITTER_API_SECRET',     // Secret de l'API
      callbackUrl: 'https://yourdomain.com/twitter-callback', // URL de redirection
      permissions: ['tweet.read', 'tweet.write'], // Permissions requises
    },
    instagram: {
      appId: 'YOUR_INSTAGRAM_APP_ID',           // ID d'application Instagram
      redirectUri: 'https://yourdomain.com/instagram-callback', // URL de redirection
      scopes: 'user_profile,user_media',        // Permissions d'accès Instagram
    },
  };
  
  export default socialNetworkConfig;
  