import React, { useState } from 'react';
import { Button, Typography, IconButton, Menu, MenuItem, Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import LoginImage from '../../assets/images/my-background-image.jpg'; // Image de fond

function HeaderComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);  // Ouvrir le menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null);  // Fermer le menu
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: 4,
        mb: 4,
        backgroundImage: `url(${LoginImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        color: '#fff',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: { xs: 2, md: 3 },
        }}
      >
        {/* Titre Viralist */}
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#fff' }}>
          Viralist
        </Typography>

        {/* Menu pour mobile */}
        {isMobile ? (
          <div>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              sx={{ color: '#fff' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to="/signup" style={{ textDecoration: 'none', width: '100%' }}>
                  <Button variant="contained" color="primary" fullWidth>
                    S'inscrire
                  </Button>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to="/login" style={{ textDecoration: 'none', width: '100%' }}>
                  <Button variant="outlined" color="secondary" fullWidth>
                    Se connecter
                  </Button>
                </Link>
              </MenuItem>
            </Menu>
          </div>
        ) : (
          // Boutons en mode desktop
          <div style={{ display: 'flex', gap: '10px' }}>
            <Link to="/signup" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="primary">
                S'inscrire
              </Button>
            </Link>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="secondary">
                Se connecter
              </Button>
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default HeaderComponent;
