/* global FB */
import socialNetworkConfig from '../../../config/socialNetworkConfig';

export const loadFacebookSdk = () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: socialNetworkConfig.facebook.appId, // Récupéré depuis le fichier de configuration
      cookie: true,
      xfbml: true,
      version: socialNetworkConfig.facebook.apiVersion,
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};
