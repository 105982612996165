import { db, auth } from '../../../config/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

export const fetchFacebookPageInfo = async () => {
    
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.error('Aucun utilisateur connecté.');
    return {};
  }

  try {
    const userRef = doc(db, 'users', currentUser.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const data = userDoc.data();
      console.log("Données Firestore :", data);  // Debugging line
      return {
        pageId: data.pageId,
        pageAccessToken: data.pageAccessToken,
      };
    } else {
      console.error('Aucune donnée trouvée pour cet utilisateur');
      return {};
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des informations de la page Facebook depuis Firestore:', error);
    return {};
  }
};
