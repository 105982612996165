import React from 'react';
import { Typography } from '@mui/material';

function PostHistoryPage() {
  return (
    <div>
      <Typography variant="h4">Historique de mes publications</Typography>
      {/* Add specific post history content here */}
    </div>
  );
}

export default PostHistoryPage;
