import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Container } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign'; // Icône de campagne
import VisibilityIcon from '@mui/icons-material/Visibility'; // Icône de visibilité
import AssessmentIcon from '@mui/icons-material/Assessment'; // Icône de résultats

function ServicesSection() {
  const services = [
    {
      title: "L'IA pour des campagnes en un instant",
      description: "Créez et lancez des campagnes marketing en quelques minutes grâce à l'automatisation par l'IA.",
      icon: <CampaignIcon sx={{ fontSize: 80, color: '#1976d2' }} />
    },
    {
      title: "Visibilité maximale, effort minimal",
      description: "Laissez l'IA gérer la publication et le timing parfait pour toucher votre audience sans effort.",
      icon: <VisibilityIcon sx={{ fontSize: 80, color: '#1976d2' }} />
    },
    {
      title: "Automatisation et gain de temps",
      description: "Automatisez vos tâches marketing répétitives pour vous concentrer sur l'essentiel.",
      icon: <AssessmentIcon sx={{ fontSize: 80, color: '#1976d2' }} />
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
          Nos Services
        </Typography>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 4,
                p: 3,
                backgroundColor: '#f9f9f9',
                textAlign: 'center',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                {service.icon}
              </Box>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ServicesSection;
