import React from 'react';
import { Typography, Button, Container, Box, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom'; 


function CallToActionSection() {
  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          backgroundColor: '#1976d2', // Couleur de fond légère pour se démarquer
          color: '#fff',
        }}
      >
        <CardContent sx={{ p: { xs: 4, md: 6 } }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Prêt à booster votre communication digitale ?
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Inscrivez-vous maintenant et commencez à améliorer votre présence en ligne dès aujourd'hui !
          </Typography>
          <Box display="flex" justifyContent="center" mt={3}>
            {/* Le bouton "S'inscrire" avec une couleur visible sur le fond */}

            <Link to="/signup" style={{ textDecoration: 'none' }}>
              <Button variant="contained" color="secondary">
                S'inscrire
              </Button>
            </Link>

          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default CallToActionSection;
