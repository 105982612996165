import React from 'react';
import HeaderComponent from '../../components/Header/Header';
import FooterComponent from '../../components/Footer/Footer';
import ServicesSection from './sections/ServicesSection';
import WhyViralistSection from './sections/WhyViralistSection';
import CallToActionSection from './sections/CallToActionSection';

function HomePage() {
  return (
    <div className="page-container">
      <HeaderComponent />
      <WhyViralistSection />
      <ServicesSection />
      <CallToActionSection />
      <FooterComponent />
    </div>
  );
}

export default HomePage;
