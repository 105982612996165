// src/services/socialNetworks/facebook/facebookPostService.js
import axios from 'axios';

const FB_API_VERSION = 'v12.0';

// Fonction pour publier des médias sur Facebook
export const uploadMediaToFacebook = async (pageId, accessToken, file) => {
  try {
    const formData = new FormData();
    formData.append('source', file);
    formData.append('access_token', accessToken);

    const mediaResponse = await axios.post(
      `https://graph.facebook.com/${FB_API_VERSION}/${pageId}/photos`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    if (mediaResponse.data.id) {
      return { media_fbid: mediaResponse.data.id };
    } else {
      console.error("Erreur lors de l'obtention de l'ID du média :", mediaResponse.data);
      return null;
    }
  } catch (error) {
    console.error("Erreur lors du téléchargement du média :", error);
    return null;
  }
};

// Fonction pour publier un post avec texte et médias sur Facebook
export const publishPostToFacebook = async (pageId, accessToken, content, attachedMediaIds) => {
  try {
    const postResponse = await axios.post(`https://graph.facebook.com/${FB_API_VERSION}/${pageId}/feed`, {
      message: content,
      attached_media: attachedMediaIds,
      access_token: accessToken,
    });

    return postResponse.data;
  } catch (error) {
    console.error('Erreur lors de la publication sur Facebook:', error);
    throw error;
  }
};
