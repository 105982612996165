import { initializeApp } from 'firebase/app';  
import { getAuth, onAuthStateChanged } from 'firebase/auth';  // Ajout de onAuthStateChanged
import { getFirestore } from 'firebase/firestore'; 
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDmt-24EcxJJqSDZ3RfkW9IapzY115aos",
  authDomain: "viralist-59357.firebaseapp.com",
  projectId: "viralist-59357",
  storageBucket: "viralist-59357.appspot.com",
  messagingSenderId: "925033861916",
  appId: "1:925033861916:web:28b49a70c966399c2a20d9",
  measurementId: "G-GGMG6FCK0Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);  // 'app' est l'instance Firebase initialisée

// Initialiser Firebase Authentication et Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);  // Firebase Storage pour gérer les fichiers

// Exporter les services pour les utiliser ailleurs dans l'application
export { db, auth, storage, onAuthStateChanged };
