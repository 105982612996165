/* global FB */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import SignUpPage from './pages/register/SignUpPage';
import LoginPage from './pages/register/LoginPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ProfilePage from './pages/Dashboard/ProfilePage';
import PostHistoryPage from './pages/Dashboard/PostHistoryPage';
import NewPostPage from './pages/Dashboard/NewPostPage';
import ProtectedRoute from './components/ProtectedRoute';
import { loadFacebookSdk } from './services/socialNetworks/facebook/facebookSdk'; // Import du SDK


function App() {

  useEffect(() => {
    loadFacebookSdk();
  }, []);
  

  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Protected routes for the dashboard */}
        <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}>
          {/* Nested routes within the dashboard */}
          <Route path="profile" element={<ProfilePage />} />
          <Route path="post-history" element={<PostHistoryPage />} />
          <Route path="new-post" element={<NewPostPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
