import React from 'react';
import { Grid, Typography, Button, Box, Card, CardContent, Container } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function WhyViralistSection() {
  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          mb: 4,
          backgroundColor: '#f9f9f9', // Fond sobre
          color: '#333',
        }}
      >
        <CardContent sx={{ p: { xs: 3, md: 5 } }}>
          <Grid container spacing={4} alignItems="center">
            {/* Icône à gauche */}
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <InfoIcon sx={{ fontSize: 150, color: '#1976d2' }} />
              </Box>
            </Grid>

            {/* Texte et bouton à droite */}
            <Grid item xs={12} md={6}>
              <Box className="text-content" maxWidth="lg">
                <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                  Pourquoi choisir Viralist ?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Viralist est votre partenaire de confiance pour développer la visibilité de votre entreprise
                  sur les réseaux sociaux. Nos outils intuitifs vous permettent de planifier, exécuter et analyser
                  vos campagnes marketing en toute simplicité.
                </Typography>
                <Box mt={2}>
                  <Button variant="contained" color="primary">
                    En savoir plus
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default WhyViralistSection;
