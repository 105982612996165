// src/components/PromptInput/PromptInput.js
import React from 'react';
import { Box, TextField, IconButton, CircularProgress } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';

const PromptInput = ({
  textContent,
  setTextContent,
  mediaFiles,
  setMediaFiles,
  generatePost,
  isPublishing,
  handleRemoveMedia,
}) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
        p: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderRadius: '8px 8px 0 0',
        zIndex: 10,
        width: '100%',
        maxWidth: { xs: '95%', sm: 600 }, // S'adapte selon la taille d'écran
        overflowX: 'hidden', // Empêche le défilement horizontal
      }}
    >
      {/* Champ de texte et boutons */}
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Décrivez votre promo ou produit..."
        value={textContent}
        onChange={(e) => setTextContent(e.target.value)}
        multiline
        maxRows={6}
        sx={{ bgcolor: 'transparent', borderRadius: 2 }}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <input
                accept="image/*,video/*"
                style={{ display: 'none' }}
                id="upload-media"
                multiple
                type="file"
                onChange={(e) => setMediaFiles([...mediaFiles, ...Array.from(e.target.files)])}
              />
              <label htmlFor="upload-media">
                <IconButton component="span" color="primary">
                  <AttachFileIcon />
                </IconButton>
              </label>
              <IconButton
                style={{ backgroundColor: '#000', color: '#fff' }}
                onClick={generatePost}
                disabled={isPublishing}
              >
                {isPublishing ? <CircularProgress size={24} color="inherit" /> : <AutorenewIcon />}
              </IconButton>
            </Box>
          ),
        }}
      />

      {/* Affichage des médias sélectionnés avec option de suppression */}
      <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }}>
        {mediaFiles.map((file, index) => (
          <Box key={index} sx={{ position: 'relative', width: 70, height: 70 }}>
            {file.type.startsWith("image") ? (
              <img
                src={URL.createObjectURL(file)}
                alt="media"
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 4 }}
              />
            ) : (
              <video
                src={URL.createObjectURL(file)}
                controls
                style={{ width: '100%', height: '100%', borderRadius: 4 }}
              />
            )}
            <IconButton
              size="small"
              sx={{ position: 'absolute', top: 2, right: 2, backgroundColor: 'rgba(0,0,0,0.5)' }}
              onClick={() => handleRemoveMedia(index)}
            >
              <CloseIcon style={{ color: '#fff', fontSize: 16 }} />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PromptInput;
