import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Container, Grid, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
import PostCard from '../../components/Card/PostCard';
import PromptInput from '../../components/PromptInput/PromptInput';
import { fetchFacebookPageInfo } from '../../services/socialNetworks/facebook/facebookPageService';
import { uploadMediaToFacebook, publishPostToFacebook } from '../../services/socialNetworks/facebook/facebookPostService';
import { auth } from '../../config/firebaseConfig';

function NewPostPage() {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [textContent, setTextContent] = useState('');
  const [generatedPosts, setGeneratedPosts] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [pageId, setPageId] = useState('');
  const [pageAccessToken, setPageAccessToken] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const pageData = await fetchFacebookPageInfo();
        setPageId(pageData.pageId);
        setPageAccessToken(pageData.pageAccessToken);
        setIsAuthLoading(false);
      } else {
        console.error('Aucun utilisateur connecté.');
        setIsAuthLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (isAuthLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 5, mb: 5, position: 'relative' }}>
        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          <CircularProgress />
        </Grid>
      </Container>
    );
  }

  const generatePost = async () => {
    try {
      const response = await axios.post('https://generatepostopenai-wcpxh4udwa-uc.a.run.app', {
        textContent: textContent,
      });

      const newPost = { content: response.data.response, mediaFiles: [...mediaFiles] };
      setGeneratedPosts([...generatedPosts, newPost]);
      setTextContent('');
      setMediaFiles([]);
    } catch (error) {
      console.error('Erreur lors de la génération de la publication :', error);
    }
  };

  const handlePublishToFacebook = async (post) => {
    if (!pageId || !pageAccessToken) {
      alert("ID de page ou jeton d'accès manquant pour publier sur Facebook.");
      return;
    }
    setIsPublishing(true);

    try {
      const attachedMediaIds = [];
      for (const file of post.mediaFiles) {
        const mediaId = await uploadMediaToFacebook(pageId, pageAccessToken, file);
        if (mediaId) {
          attachedMediaIds.push(mediaId);
        } else {
          alert("Erreur lors du téléchargement de certains médias. Vérifiez le format et la taille des fichiers.");
          setIsPublishing(false);
          return;
        }
      }

      const postResponse = await publishPostToFacebook(pageId, pageAccessToken, post.content, attachedMediaIds);

      if (postResponse.id) {
        alert('Publication réussie sur Facebook!');
      } else {
        alert('Erreur lors de la publication. Veuillez vérifier vos autorisations et réessayer.');
      }
    } catch (error) {
      alert('Erreur lors de la publication. Veuillez vérifier vos autorisations et le format des fichiers.');
    } finally {
      setIsPublishing(false);
    }
  };

  const handleRemoveMedia = (index) => {
    setMediaFiles(mediaFiles.filter((_, i) => i !== index));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      {/* Conteneur défilant pour les PostCard avec un padding-bottom pour éviter le chevauchement avec PromptInput */}
      <Box sx={{ flex: 1, overflowY: 'auto', paddingBottom: '80px' }}>
        <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
          {generatedPosts.map((post, postIndex) => (
            <Grid item xs={12} key={postIndex}>
              <Box sx={{ mb: 2 }}> {/* Ajout d'un margin-bottom pour écarter les cartes entre elles */}
                <PostCard
                  content={post.content}
                  mediaFiles={post.mediaFiles}
                  onPublishToFacebook={() => handlePublishToFacebook(post)}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Composant PromptInput positionné en bas */}
      <PromptInput
        textContent={textContent}
        setTextContent={setTextContent}
        mediaFiles={mediaFiles}
        setMediaFiles={setMediaFiles}
        generatePost={generatePost}
        isPublishing={isPublishing}
        handleRemoveMedia={handleRemoveMedia}
      />
    </Box>
  );
}

export default NewPostPage;
