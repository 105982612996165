import React, { useState } from 'react';
import { auth, db } from '../../config/firebaseConfig'; // Import Firebase Auth et Firestore
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  Avatar,
  Alert,
  useMediaQuery,
  CardMedia
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from '@mui/material/styles';
import SignupImage from '../../assets/images/my-background-image.jpg'; // Chemin de l'image

function SignUpPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState(''); // Ajout du nom de l'entreprise
  const [firstName, setFirstName] = useState(''); // Prénom du détenteur
  const [lastName, setLastName] = useState(''); // Nom du détenteur
  const [role, setRole] = useState(''); // Rôle du détenteur dans l'entreprise
  const [sector, setSector] = useState(''); // Nouveau champ pour le secteur d'activité
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Pour vérifier si c'est un écran mobile

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      // Création de l'utilisateur avec Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Préremplissage de la company dans Firestore avec l'UID de l'utilisateur
      const companyRef = doc(db, 'companies', user.uid);
      await setDoc(companyRef, {
        name: companyName,
        sector: sector, // Ajout du secteur d'activité
        slogan: '',
        description: '',
        address: '',
        phone: '',
        email: email, // Préremplissage avec l'email utilisé à l'inscription
        website: '',
        taxId: '',
        registrationNumber: '',
        logoUrl: '',
        owners: [
          {
            firstName: firstName,
            lastName: lastName,
            role: role,
            email: email, // Email du détenteur de compte
            uid: user.uid // Ajout de l'UID pour identifier chaque owner
          }
        ]
      });

      console.log('Utilisateur créé avec une company préremplie et un owner principal');

      // Connexion automatique après inscription
      await signInWithEmailAndPassword(auth, email, password);

      // Redirection après inscription
      navigate('/dashboard');
    } catch (error) {
      console.error('Erreur lors de la création du compte:', error);
      setError('Erreur lors de la création du compte. Veuillez réessayer.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 5, mb: 5 }}>
      <Card sx={{ boxShadow: 3, borderRadius: 4 }}>
        <CardMedia
          component="img"
          height="200"
          image={SignupImage}
          alt="Inscription"
          sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        />
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
              Créez votre compte
            </Typography>

            {/* Formulaire */}
            <Box component="form" onSubmit={handleSignUp} sx={{ width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="companyName"
                    label="Nom de l'entreprise"
                    name="companyName"
                    autoFocus
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="sector"
                    label="Secteur d'activité"
                    name="sector"
                    value={sector}
                    onChange={(e) => setSector(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="Prénom"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Nom"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="role"
                    label="Rôle dans l'entreprise"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresse Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmez le mot de passe"
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Grid>
              </Grid>

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              {/* Bouton "S'inscrire" déplacé à l'intérieur du formulaire */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
              >
                S'inscrire
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default SignUpPage;
