import React, { useState, useEffect } from 'react';
import {
  TextField,
  IconButton,
  Box,
  Avatar,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Container,
  useMediaQuery,
  useTheme
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { db, storage, onAuthStateChanged } from '../../config/firebaseConfig';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { auth } from '../../config/firebaseConfig';
import LoginImage from '../../assets/images/my-background-image.jpg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Importer la page de configuration des réseaux sociaux
import SocialNetMediaConfigurationPage from './SocialMediaAuthorization/SocialNetMediaConfigurationPage';

function ProfilePage() {
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState({
    name: '',
    slogan: '',
    description: '',
    address: '',
    phone: '',
    email: '',
    website: '',
    taxId: '',
    registrationNumber: '',
    logoUrl: '',
  });
  const [editingField, setEditingField] = useState(null);
  const [user, setUser] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchCompanyData(currentUser);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCompanyData = async (currentUser) => {
    try {
      const companyRef = doc(db, 'companies', currentUser.uid);
      const companyDoc = await getDoc(companyRef);
      if (companyDoc.exists()) {
        setCompanyData(companyDoc.data());
      } else {
        console.error('Aucune donnée trouvée pour cet utilisateur');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
    setLoading(false);
  };

  const handleEditField = (field) => {
    setEditingField(field);
  };

  const handleSaveField = async (field) => {
    if (user) {
      try {
        const companyRef = doc(db, 'companies', user.uid);
        await updateDoc(companyRef, { [field]: companyData[field] });
        setEditingField(null);
      } catch (error) {
        console.error('Erreur lors de la sauvegarde des données:', error);
      }
    } else {
      console.error('Utilisateur non connecté');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setCompanyData({ ...companyData, phone: value });
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file && user) {
      const logoRef = ref(storage, `companyLogos/${user.uid}.jpg`);
      await uploadBytes(logoRef, file);
      const logoUrl = await getDownloadURL(logoRef);
      setCompanyData({ ...companyData, logoUrl });
      await updateDoc(doc(db, 'companies', user.uid), { logoUrl });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f4f4f4">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 5, mb: 5, bgcolor: '#f4f4f4', p: 3, borderRadius: 4 }}>
      {/* Profile Picture with Background */}
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 4,
          mb: 4,
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#fff',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            p: { xs: 2, md: 4 },
          }}
        >
          <input
            type="file"
            accept="image/*"
            id="upload-logo"
            style={{ display: 'none' }}
            onChange={handleLogoChange}
          />
          <label htmlFor="upload-logo">
            <IconButton component="span" sx={{ position: 'absolute', right: 16, top: 16, color: '#fff' }}>
              <EditIcon />
            </IconButton>
          </label>
          <Avatar
            src={companyData.logoUrl || ''}
            alt="Logo de l'entreprise"
            sx={{
              width: { xs: 80, md: 120 },
              height: { xs: 80, md: 120 },
              border: '3px solid #fff',
              marginBottom: '-40px',
            }}
          />
        </CardContent>
        <Typography variant="h5" align="center" sx={{ mt: 2, color: '#fff' }}>
          Profil de l'entreprise
        </Typography>
      </Card>

      {/* Informations de l'entreprise sous forme de Cards */}
      {[
        { label: 'Nom de l\'entreprise', field: 'name' },
        { label: 'Slogan de l\'entreprise', field: 'slogan' },
        { label: 'Description', field: 'description' },
        {
          label: 'Téléphone',
          field: 'phone',
          customComponent: (
            <PhoneInput
              country={'fr'}
              value={companyData.phone}
              onChange={handlePhoneChange}
              containerStyle={{ width: '100%' }}
              inputStyle={{ width: '100%' }}
            />
          ),
        },
        { label: 'Email', field: 'email' },
        { label: 'Site Web', field: 'website' },
        { label: 'Numéro d\'identification fiscale', field: 'taxId' },
        { label: 'Numéro d\'enregistrement', field: 'registrationNumber' },
      ].map(({ label, field, customComponent }) => (
        <Card key={field} sx={{ boxShadow: 3, borderRadius: 4, mb: 3, backgroundColor: '#fff' }}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              gap: isMobile ? 1 : 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  minWidth: isMobile ? '100%' : '250px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {label}
                <IconButton
                  onClick={() => (editingField === field ? handleSaveField(field) : handleEditField(field))}
                  sx={{
                    ml: isMobile ? 1 : 'auto',
                    color: editingField === field ? 'primary.main' : 'inherit',
                  }}
                >
                  {editingField === field ? <SaveIcon /> : <EditIcon />}
                </IconButton>
              </Typography>
            </Box>

            {editingField === field ? (
              customComponent ? (
                customComponent
              ) : (
                <TextField
                  fullWidth
                  multiline={field === 'description'}
                  minRows={field === 'description' ? 3 : 1}
                  value={companyData[field] || ''}
                  name={field}
                  onChange={handleInputChange}
                  sx={{ mt: isMobile ? 1 : 0 }}
                />
              )
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Typography sx={{ mt: isMobile ? 1 : 0, flexGrow: 1 }}>
                  {companyData[field] || 'Non renseigné'}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      ))}

      {/* Configuration des Réseaux Sociaux */}
      <Box sx={{ mt: 5 }}>
        <SocialNetMediaConfigurationPage />
      </Box>
    </Container>
  );
}

export default ProfilePage;
