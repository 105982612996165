import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryIcon from '@mui/icons-material/History';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate, Outlet } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function DashboardPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is small
  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer

  // Function to handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Function to handle burger menu toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Sidebar content (used for both mobile and desktop)
  const drawerContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* "Viralist" text at the top */}
      <Box sx={{ p: 2, textAlign: 'center', fontWeight: 'bold', fontSize: 24 }}>
        Viralist
      </Box>
      <List>
        <ListItem button onClick={() => navigate('/dashboard/profile')}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Profil de mon entreprise" />
        </ListItem>

        <ListItem button onClick={() => navigate('/dashboard/post-history')}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Historique de mes publications" />
        </ListItem>

        <ListItem button onClick={() => navigate('/dashboard/new-post')}>
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Nouvelle publication" />
        </ListItem>
      </List>

      {/* Push logout button to the bottom */}
      <Box sx={{ flexGrow: 1 }} /> {/* This will push the logout button to the bottom */}
      <ListItem button onClick={handleLogout} sx={{ color: 'red' }}>
        <ListItemIcon sx={{ color: 'red' }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Se déconnecter" />
      </ListItem>
    </Box>
  );

  return (
    <Box display="flex">
      {/* AppBar for mobile with burger menu icon */}
      {isMobile && (
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Tableau de bord
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Sidebar for desktop and mobile view */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }}
        ModalProps={{ keepMounted: true }} // Better mobile performance
      >
        {drawerContent}
      </Drawer>

      {/* Main content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {isMobile && <Toolbar />} {/* Add space for mobile AppBar */}
        <Outlet />
      </Box>
    </Box>
  );
}

export default DashboardPage;
