// src/components/Footer/Footer.js
import React from 'react';
import { Typography, Link } from '@mui/material';
import './Footer.css';

function FooterComponent() {
  return (
    <footer className="footer">
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Viralist | 
        <Link href="#" color="inherit" style={{ marginLeft: '10px' }}>
          Mentions légales
        </Link> | 
        <Link href="#" color="inherit" style={{ marginLeft: '10px' }}>
          Politique de confidentialité
        </Link>
      </Typography>
    </footer>
  );
}

export default FooterComponent;
