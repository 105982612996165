// src/pages/Dashboard/SocialMediaAuthorization/FacebookAuth.js

import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import SocialMediaAuthorizationCard from '../../../components/Card/SocialMediaAuthorizationCard';
import socialNetworkConfig from '../../../config/socialNetworkConfig';
import { db, auth } from '../../../config/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

/* global FB */

const FacebookAuth = () => {
  // Fonction pour gérer l'autorisation Facebook avec le SDK
  const handleAuthorizeFacebook = () => {
    if (typeof FB !== 'undefined') {
      // Lancement de la demande d'autorisation avec les permissions définies dans socialNetworkConfig
      FB.login((response) => {
        if (response.authResponse) {
          console.log('Utilisateur connecté avec Facebook.');
          const userAccessToken = response.authResponse.accessToken;

          // Récupérer les pages administrées par l'utilisateur
          FB.api('/me/accounts', { access_token: userAccessToken }, (response) => {
            if (response && !response.error) {
              console.log('Pages récupérées:', response.data);

              // Ici, on récupère la première page (ou ajouter une logique pour sélectionner une page spécifique)
              const page = response.data[0]; // Remplacez [0] par l'index de la page souhaitée ou une logique dynamique
              const pageId = page.id;
              const pageAccessToken = page.access_token;
              const pageName = page.name;

              // Sauvegarde des informations de la page dans Firestore
              saveFacebookPageToFirestore(pageId, pageAccessToken, pageName);
            } else {
              console.error('Erreur lors de la récupération des pages:', response.error);
            }
          });
        } else {
          console.log('L’utilisateur a annulé la connexion.');
        }
      }, { scope: socialNetworkConfig.facebook.scopes });
    } else {
      console.error('SDK Facebook non chargé.');
    }
  };

  // Fonction pour sauvegarder les informations de la page Facebook dans Firestore
  const saveFacebookPageToFirestore = async (pageId, pageAccessToken, pageName) => {
    try {
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        await setDoc(userRef, {
          SocialNetWorkname: "Facebook",
          pageId: pageId,
          pageAccessToken: pageAccessToken,
          pageName: pageName,
          timestamp: new Date().toISOString(),
        }, { merge: true });
        console.log('Informations de la page Facebook enregistrées dans Firestore');
      } else {
        console.error('Aucun utilisateur connecté.');
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde dans Firestore:', error);
    }
  };

  return (
    <SocialMediaAuthorizationCard
      networkName="Facebook"
      networkLogo={<FacebookIcon sx={{ fontSize: 40, color: '#3b5998' }} />}
      description={
        <>
          Pour publier sur vos pages Facebook, nous avons besoin des autorisations suivantes :
          <ul>
            <li>Gestion des publications : <strong>pages_manage_posts</strong></li>
            <li>Accès aux données de la page : <strong>pages_read_engagement</strong></li>
            <li>Gestion des interactions : <strong>pages_manage_engagement</strong></li>
          </ul>
        </>
      }
      onAuthorize={handleAuthorizeFacebook}
    />
  );
};

export default FacebookAuth;
